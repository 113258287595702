module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Travelworld","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/travelworld-icon.png","cache_busting_mode":"query","include_favicon":true,"legacy":true,"theme_color_in_head":true,"cacheDigest":"a2ea7a1348c19640e3109239c0123b76"},
    },{
      plugin: require('../node_modules/@qite/gatsby-source-tide/gatsby-browser.js'),
      options: {"plugins":[],"host":"https://tide-travelworld8527.tidesoftware.be","apiKey":"ca028e2f-217a-4e4a-8e7e-8d556be17de3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-i18next/gatsby-browser.js'),
      options: {"plugins":[],"localeJsonSourceName":"locale","languages":["nl-BE","fr-BE"],"defaultLanguage":"nl-BE","siteUrl":"https://travelworld.be/","trailingSlash":"always","i18nextOptions":{"interpolation":{"escapeValue":false},"keySeparator":".","nsSeparator":false},"pages":[{"matchPath":"/:lang?/404","getLanguageFromPath":false},{"matchPath":"/:lang?","getLanguageFromPath":true,"excludeLanguages":[["nl-BE","fr-BE"]]},{"matchPath":"/:lang?/(.*)","getLanguageFromPath":true,"excludeLanguages":[["nl-BE","fr-BE"]]}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-ts-config/gatsby-browser.js'),
      options: {"plugins":[],"projectRoot":"/home/jenkins/workspace/Travelworld/Publish Production","configDir":".gatsby"},
    }]
