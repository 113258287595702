// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-agent-page-template-tsx": () => import("./../../../src/templates/agent-page-template.tsx" /* webpackChunkName: "component---src-templates-agent-page-template-tsx" */),
  "component---src-templates-booking-page-template-tsx": () => import("./../../../src/templates/booking-page-template.tsx" /* webpackChunkName: "component---src-templates-booking-page-template-tsx" */),
  "component---src-templates-destinations-page-template-tsx": () => import("./../../../src/templates/destinations-page-template.tsx" /* webpackChunkName: "component---src-templates-destinations-page-template-tsx" */),
  "component---src-templates-homepage-template-tsx": () => import("./../../../src/templates/homepage-template.tsx" /* webpackChunkName: "component---src-templates-homepage-template-tsx" */),
  "component---src-templates-hotel-page-template-tsx": () => import("./../../../src/templates/hotel-page-template.tsx" /* webpackChunkName: "component---src-templates-hotel-page-template-tsx" */),
  "component---src-templates-login-page-template-tsx": () => import("./../../../src/templates/login-page-template.tsx" /* webpackChunkName: "component---src-templates-login-page-template-tsx" */),
  "component---src-templates-page-template-tsx": () => import("./../../../src/templates/page-template.tsx" /* webpackChunkName: "component---src-templates-page-template-tsx" */),
  "component---src-templates-region-page-template-tsx": () => import("./../../../src/templates/region-page-template.tsx" /* webpackChunkName: "component---src-templates-region-page-template-tsx" */),
  "component---src-templates-roundtrip-page-template-tsx": () => import("./../../../src/templates/roundtrip-page-template.tsx" /* webpackChunkName: "component---src-templates-roundtrip-page-template-tsx" */),
  "component---src-templates-search-page-template-tsx": () => import("./../../../src/templates/search-page-template.tsx" /* webpackChunkName: "component---src-templates-search-page-template-tsx" */),
  "component---src-templates-theme-page-template-tsx": () => import("./../../../src/templates/theme-page-template.tsx" /* webpackChunkName: "component---src-templates-theme-page-template-tsx" */),
  "component---src-templates-themes-page-template-tsx": () => import("./../../../src/templates/themes-page-template.tsx" /* webpackChunkName: "component---src-templates-themes-page-template-tsx" */)
}

