import {
  TideItem,
  TideItemForAccomodation,
  TideItemForCountry,
  TideItemForDestinations,
  TideItemForGeographicalRegion,
  TideItemForHotel,
  TideItemForHotelListItemContentTravelData,
  TideItemForItinerarySegment,
  TideItemForLinkedExcursion,
  TideItemForLinkedHotel,
  TideItemForNavigationLink,
  TideItemForNavigationSection,
  TideItemForPage,
  TideItemForRegion,
  TideItemForRoundtrip,
  TideItemForSearchPage,
  TideItemForTeamComponent,
  TideItemForTeamMember,
  TideItemForTheme,
  TideItemForThemes,
  TideItemForUsp,
  TideItemForUspGroup,
  TideProduct,
  TideItemForAgentPage,
} from "./types";
import { compact, endsWith, isEmpty, join, kebabCase, omit, startsWith, trim } from "lodash";
import { format as dateFnsFormat, format } from "date-fns";

import JsonURL from "@jsonurl/jsonurl";
import React from "react";
import { Room } from "./app/search-results/qsm/room-picker";
import { BookingPackage, MemberInfo } from "@qite/tide-client/build/types";
import { jwtDecode } from "jwt-decode";
import { MemberInfoPayload } from "./components/login/login-page";

export function buildClassName(parts: any[]) {
  return join(compact(parts), " ");
}

export function getHrefFromNavigationLink(navigationLink: TideItemForNavigationLink) {
  if (isEmpty(navigationLink.content?.general?.path)) {
    return navigationLink.content?.general?.url ?? undefined;
  } else {
    const path = navigationLink.content?.general?.path as string;
    return `/${trim(path, "/")}/`;
  }
}

export function generatePath(
  tideItem:
    | TideItemForPage
    | TideItemForSearchPage
    | TideItemForDestinations
    | TideItemForThemes
    | TideItemForTheme
    | TideItemForRegion
    | TideItemForCountry
    | TideItemForRoundtrip
    | TideItemForHotel
    | TideItemForAgentPage,
  parentPath?: string
) {
  const { slug } = tideItem?.content?.general ?? {};
  const trimmedParentPath = trim(parentPath ?? "", "/");
  const fullPath = `${trimmedParentPath}/${kebabCase(
    (!isEmpty(slug) ? slug : tideItem?.name) ?? ""
  )}`;
  const fullPathWithTrailingSlash = endsWith(fullPath, "/") ? fullPath : `${fullPath}/`;
  return startsWith(fullPathWithTrailingSlash, "/")
    ? fullPathWithTrailingSlash
    : `/${fullPathWithTrailingSlash}`;
}

export function getChildItemsOfType(item: TideItem, templateName: string) {
  if (item?.childItems) {
    return (item?.childItems ?? []).filter((childItem) => childItem?.templateName === templateName);
  } else {
    return [];
  }
}

export function generateCountryOrRegionPath(
  countryOrRegion: TideItemForCountry | TideItemForRegion | null
) {
  if (countryOrRegion?.parentItem?.parentItem) {
    const destinationsPath = generatePath(
      countryOrRegion?.parentItem?.parentItem as TideItemForDestinations
    );
    return generatePath(countryOrRegion, destinationsPath);
  }
  return undefined;
}

export function generateRegionPath(region: TideItemForRegion | null) {
  if (region?.parentItem) {
    const countryPath = generateCountryOrRegionPath(region.parentItem as TideItemForCountry);
    return generatePath(region, countryPath);
  }
  return undefined;
}

export function generateProductPath(
  product: TideItemForHotel | TideItemForRoundtrip | undefined | null
) {
  if (product?.parentItem?.parentItem?.parentItem) {
    if (product?.parentItem?.parentItem?.parentItem?.parentItem) {
      const regionPath = generateRegionPath(product?.parentItem as TideItemForRegion);
      return generatePath(product as TideItemForRoundtrip, regionPath);
    } else {
      const countryOrRegionPath = generateCountryOrRegionPath(
        product?.parentItem as TideItemForCountry | TideItemForRegion
      );
      return generatePath(product as TideItemForRoundtrip, countryOrRegionPath);
    }
  }
  return undefined;
}

export function generateParameterizedProductPath(
  product: TideItemForHotel | TideItemForRoundtrip | undefined | null,
  rooms: Room[],
  from: Date | null,
  to: Date | null,
  accommodationCode?: string,
  regimeCode?: string
) {
  const productPath = generateProductPath(product);
  if (!productPath) {
    return undefined;
  }

  const params: Record<string, string> = {};

  // Rooms
  if (rooms && rooms.length > 0) {
    const serializedRooms = JsonURL.stringify(
      rooms.map((room) => omit(room, ["children"])),
      { AQF: true }
    );
    if (serializedRooms) {
      params["rooms"] = serializedRooms;
    }
  }

  // From
  if (from) {
    const serializedFrom = format(from, "yyyyMMdd");
    if (serializedFrom) {
      params["from"] = serializedFrom;
    }
  }

  // To
  if (to) {
    const serializedTo = format(to, "yyyyMMdd");
    if (serializedTo) {
      params["to"] = serializedTo;
    }
  }

  if (accommodationCode) {
    params["accommodation"] = accommodationCode;
  }

  if (regimeCode) {
    params["regime"] = regimeCode;
  }

  const paramString = Object.keys(params)
    .map((key) => `${key}=${params[key]}`)
    .join("&");

  return paramString ? `${productPath}?${paramString}` : productPath;
}

export function getChildNavigationSections(navigationSection: TideItemForNavigationSection) {
  return getChildItemsOfType(
    navigationSection,
    "Navigation Section"
  ) as TideItemForNavigationSection[];
}

export function getChildNavigationLinks(navigationSection: TideItemForNavigationSection) {
  return getChildItemsOfType(navigationSection, "Navigation Link") as TideItemForNavigationLink[];
}

export function getChildUsps(uspGroup: TideItemForUspGroup) {
  return getChildItemsOfType(uspGroup, "USP") as TideItemForUsp[];
}

export function getItinerarySegments(roundtrip: TideItemForRoundtrip) {
  return getChildItemsOfType(roundtrip, "Itinerary Segment") as TideItemForItinerarySegment[];
}

export function getLinkedHotels(itinerarySegment: TideItemForItinerarySegment) {
  return getChildItemsOfType(itinerarySegment, "Linked Hotel") as TideItemForLinkedHotel[];
}

export function getLinkedExcursions(itinerarySegment: TideItemForItinerarySegment) {
  return getChildItemsOfType(itinerarySegment, "Linked Excursion") as TideItemForLinkedExcursion[];
}

export function getChildCountries(geographicalRegion: TideItemForGeographicalRegion) {
  return getChildItemsOfType(geographicalRegion, "Country") as TideItemForCountry[];
}

export function getChildRegions(geographicalRegion: TideItemForGeographicalRegion) {
  return getChildItemsOfType(geographicalRegion, "Region") as TideItemForRegion[];
}

export function getCountryChildRegions(country: TideItemForCountry) {
  return getChildItemsOfType(country, "Region") as TideItemForRegion[];
}

export function getChildRoundtrips(regionOrCountry: TideItemForRegion | TideItemForCountry) {
  return getChildItemsOfType(regionOrCountry, "Roundtrip") as TideItemForRoundtrip[];
}

export function getChildHotels(regionOrCountry: TideItemForRegion | TideItemForCountry) {
  return getChildItemsOfType(regionOrCountry, "Hotel") as TideItemForHotel[];
}

export function getChildAccomodations(hotel: TideItemForHotel | TideItemForRoundtrip) {
  return getChildItemsOfType(hotel, "Accomodation") as TideItemForAccomodation[];
}

export function getChildLinkedExcursions(hotel: TideItemForHotel) {
  return getChildItemsOfType(hotel, "Linked Excursion") as TideItemForLinkedExcursion[];
}

export function getChildThemes(themes: TideItemForThemes) {
  return getChildItemsOfType(themes, "Theme") as TideItemForTheme[];
}

export function getChildTeamMembers(teamComponent: TideItemForTeamComponent) {
  return getChildItemsOfType(teamComponent, "Team Member") as TideItemForTeamMember[];
}

export function isValidEmail(email: string) {
  return !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);
}

export function formatDate(date: Date, format: string) {
  return dateFnsFormat(date, format);
}

export function setImageParameters(
  imageUrl: string,
  parameters: {
    width?: number;
    height?: number;
    widthRatio?: number;
    heightRatio?: number;
    mode?: "boxpad" | "crop" | "min" | "max" | "stretch";
    anchor?:
      | "top"
      | "right"
      | "bottom"
      | "left"
      | "topleft"
      | "topright"
      | "bottomleft"
      | "bottomright";
  }
) {
  try {
    const url = new URL(imageUrl);
    const urlParams = url.searchParams;

    if (parameters.width) {
      urlParams.set("width", parameters.width.toString());
    }

    if (parameters.height) {
      urlParams.set("height", parameters.height.toString());
    }

    if (parameters.widthRatio) {
      urlParams.set("widthratio", parameters.widthRatio.toString());
    }

    if (parameters.heightRatio) {
      urlParams.set("heightratio", parameters.heightRatio.toString());
    }

    if (parameters.mode) {
      urlParams.set("mode", parameters.mode);
    }

    if (parameters.anchor) {
      urlParams.set("anchor", parameters.anchor);
    }

    url.search = urlParams.toString();

    return url.toString();
  } catch {
    return imageUrl;
  }
}

export function processPlaceholders(content: string, values: Record<string, string>): string {
  Object.keys(values).forEach((key) => {
    content = content.replace(new RegExp(`{{${key}}}`, "gi"), values[key]);
  });

  return content;
}

export function mapMinDurationType(minDurationType?: number) {
  if (minDurationType === 0) {
    return "hours";
  } else if (minDurationType === 1) {
    return "days";
  } else if (minDurationType === 2) {
    return "nights";
  }
  return undefined;
}

export function scrollToAnchor(anchorId: string) {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    const anchorElement = document.getElementById(anchorId);

    if (anchorElement) {
      const top = anchorElement.getBoundingClientRect().top;

      window.scrollBy({
        top: top,
        behavior: "smooth",
      });
    }
  }
}

export function handleAnchorClick(event: React.MouseEvent<HTMLAnchorElement>) {
  if (typeof window !== "undefined" && typeof document !== "undefined") {
    event.preventDefault();

    const href = event.currentTarget.href;
    const hashIndex = href.indexOf("#");

    scrollToAnchor(href.substring(hashIndex + 1));
  }
}

export function compileRequest(
  product: TideItemForHotel | TideItemForRoundtrip,
  fromDate: Date,
  toDate: Date,
  rooms: Room[]
) {
  var requestString = `${product.id}_${fromDate.toISOString()}_${toDate.toISOString()}`;
  rooms.forEach((room) => {
    requestString += `_A${room.adults}`;
    room.childAges.forEach((childAge) => {
      requestString += `_C${childAge}`;
    });
  });
  return requestString;
}

export const formatter = new Intl.NumberFormat("nl-BE", {
  style: "currency",
  currency: "EUR",
  minimumFractionDigits: 2,
  useGrouping: true,
});

export const selectCorrectRoomInBookingPackage = (
  rooms: Room[],
  bookingPackage?: BookingPackage
) => {
  const activeOption = bookingPackage?.options.find((x) => x.isSelected);
  if (activeOption) {
    rooms.forEach((r, i) => {
      const room = activeOption.rooms[i];
      if (room) {
        const previousOption = room.options.find((x) => x.isSelected);
        const newOption = room.options.find(
          (x) =>
            x.accommodationCode === r.accommodationCode &&
            ((!r.regimeCode && x.regimeCode === null) || x.regimeCode === r.regimeCode)
        );

        if (
          previousOption &&
          newOption &&
          previousOption?.entryLineGuid !== newOption.entryLineGuid
        ) {
          previousOption.isSelected = false;
          newOption.isSelected = true;
        }
      }
    });
  }

  return bookingPackage;
};

export const selectCorrectRoomInQsmRooms = (rooms: Room[], bookingPackage: BookingPackage) => {
  let roomsChanged = false;
  const activeOption = bookingPackage?.options.find((x) => x.isSelected);
  if (activeOption) {
    if (rooms && rooms.length) {
      rooms.forEach((r, i) => {
        if (r.accommodationCode) {
          const bookingPackageRooms = activeOption.rooms[i];
          const bookingPackageRoom = bookingPackageRooms.options.find((x) => x.isSelected);
          if (bookingPackageRoom) {
            if (
              bookingPackageRoom.accommodationCode !== r.accommodationCode ||
              bookingPackageRoom.regimeCode !== r.regimeCode
            ) {
              r.accommodationCode = bookingPackageRoom.accommodationCode;
              r.regimeCode = bookingPackageRoom.regimeCode;
              roomsChanged = true;
            }
          }
        }
      });
    }
  }
  return roomsChanged;
};

export const decodeTokenToMemberInfo = (token: string): MemberInfo | undefined => {
  if (!token) {
    return undefined;
  }
  const decodedToken = jwtDecode<MemberInfoPayload>(token);
  let member: MemberInfo | undefined = undefined;
  // check if token is not expired
  if (decodedToken?.exp && decodedToken.exp * 1000 < new Date().getTime()) {
    return undefined;
  }
  if (decodedToken) {
    member = {
      id: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"]
        ? parseInt(decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"])
        : 0,
      companyId: decodedToken.company ? parseInt(decodedToken.company) : 0,
      name: decodedToken.unique_name,
      email: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/emailaddress"],
      language: decodedToken.language,
      agentId: decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"]
        ? parseInt(decodedToken["http://schemas.xmlsoap.org/ws/2005/05/identity/claims/sid"])
        : 0,
    } as MemberInfo;
  }
  return member;
};

export const isValidDate = (d: string) => {
  return !isNaN(new Date(d).getTime());
};
