import React, { useContext, useReducer } from "react";
import { Action, MemberState, setMember } from "./reducer";
import { decodeTokenToMemberInfo } from "../../utils";

interface ProviderProps {
  initialState: MemberState;
  reducer: (state: MemberState, action: Action) => MemberState;
}

const Store = React.createContext<Partial<[MemberState, React.Dispatch<Action>]>>([]);
Store.displayName = "MemberStore";

export const useMemberStore = () => useContext(Store)!;

export const MemberStoreProvider: React.FC<ProviderProps> = ({
  children,
  initialState,
  reducer,
}) => {
  if (typeof sessionStorage !== "undefined") {
    const sessionToken = sessionStorage.getItem("token");
    if (sessionToken) {
      const member = decodeTokenToMemberInfo(sessionToken);
      if (member) {
        initialState.member = member;
      } else {
        sessionStorage.removeItem("token");
      }
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState);
  return <Store.Provider value={[state, dispatch]}>{children}</Store.Provider>;
};
