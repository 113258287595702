import React from "react";
import { MemberStoreProvider } from "../src/app/member-store/context";
import { initialMemberState, memberReducer } from "../src/app/member-store/reducer";
import "../slicing/src/assets/scss/main.scss";
import "react-image-lightbox/style.css";

export const wrapRootElement = ({ element }: any) => (
  <MemberStoreProvider initialState={initialMemberState} reducer={memberReducer}>
    {element}
  </MemberStoreProvider>
);
