import { MemberInfo } from "@qite/tide-client/build/types";

export interface MemberState {
  member?: MemberInfo;
}

const actionPrefix = "APP/MEMBER";

export const SET_MEMBER = `${actionPrefix}/SET_MEMBER`;
export const setMember = (member?: MemberInfo) => {
  return {
    type: SET_MEMBER,
    member,
  };
};

export type Action = ReturnType<typeof setMember>;

export let initialMemberState: MemberState = {
  member: undefined,
};

const runReducer = (state: MemberState, action: Action): MemberState => {
  switch (action.type) {
    case SET_MEMBER:
      return { ...state, member: (action as ReturnType<typeof setMember>).member };
    default:
      return state;
  }
};

export const memberReducer = (
  state: MemberState = initialMemberState,
  action: Action
): MemberState => {
  const newState = runReducer(state, action);

  /*
  console.groupCollapsed(action.type);
  console.info("action:", action);
  console.info("new state:", newState);
  console.info("old state:", state);
  console.groupEnd();
  */

  return newState;
};
